import { type ReactNode } from 'react'

type Props = {
  children: ReactNode
}

export function Underline({ children }: Props) {
  return <span className="underline">{children}</span>
}

export let scheme = {
  render: Underline.name,
  description: 'Displays underlined content',
  children: ['node'],
}
