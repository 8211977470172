import { type ReactNode } from 'react'

type Props = {
  children: ReactNode
}

export function Box({ children }: Props) {
  return <div className="p-4 text-center">{children}</div>
}

export let scheme = {
  render: Box.name,
  description: 'Displays content in a box',
  children: ['node'],
}
